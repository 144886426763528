@use "./theme";

html, body {
  @apply h-full;
}

body {
  @apply m-0;
  @apply font-sans;
}

.mdc-notched-outline__notch {
  border-right: none;
}

.mat-tree-node {
  .mat-primary.mat-mdc-button-base:not(.mdc-icon-button) {
    color: var(--mdc-text-button-label-text-color, inherit) !important;
  }
}

.truthy {
  color: var(--accept-color) !important;
}

.falsy {
  color: var(--decline-color) !important;
}

// A workaround to ensure that the progress bar indicator has a visible animation
// for some odd reason the track color is the same as the indicator color.
// this will force the track color to be different from the indicator color
.mat-mdc-progress-bar {
  --mdc-linear-progress-track-color: var(--primary-100) !important;
}
